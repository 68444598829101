import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';

import StyledLink from '@makeship/core/lib/components/Link';
import posthog from 'posthog-js';
import Modal from '..';
import { Caption, S2 } from '../../Typography';
import { modalTheme } from '../../../styles/themes';

const ModalContent = styled.div`
  width: 100%;
  padding: 24px 24px 45px 24px;
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 24px;
  }
`;

const CancelIcon = styled.div`
  cursor: pointer;
  margin-left: auto;
`;

const ModalHeader = styled.div``;

const ImageDesktopWrapper = styled.div`
  display: block;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: none;
  }
`;

const ImageMobileWrapper = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: block;
  }
`;

const TermsAndConditionsWrapper = styled.div`
  margin-top: 10px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 26px;
    margin-left: 0px;
  }
`;

const TermsTitle = styled(S2)``;

const TermsContent = styled(Caption)`
  margin-top: 10px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-bottom: 60px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-bottom: 20px;
  }
`;

const giftingModalTheme = {
  ...modalTheme,
  content: {
    ...modalTheme.content,
    width: '750px',
    borderRadius: '50px',
  },
};

type MakeGoodModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  modalLocation: string;
};

const MakeGoodModal: React.FC<MakeGoodModalProps> = ({ isOpen, closeModal, modalLocation }: MakeGoodModalProps) => (
  <Modal isOpen={isOpen} onRequestClose={closeModal} theme={giftingModalTheme}>
    <ModalContent>
      <CancelIcon onClick={closeModal}>
        <Image src="/assets/icons/cancel-icon.svg" width="16" height="16" quality={90} alt="close" />
      </CancelIcon>
      <ModalHeader>
        <ImageDesktopWrapper>
          <Image
            src="/assets/make-good/Make-Good_Logo-desktop.png"
            width={340}
            height={100}
            quality={90}
            alt="Make Good 2025 brought to you in support of CFI"
          />
        </ImageDesktopWrapper>
        <ImageMobileWrapper>
          <Image
            src="/assets/make-good/Make-Good_Logo.png"
            width={176}
            height={66}
            quality={90}
            alt="Make Good 2025 brought to you in support of CFI"
          />
        </ImageMobileWrapper>
      </ModalHeader>
      <TermsAndConditionsWrapper>
        <TermsTitle>Terms & Conditions</TermsTitle>
        <TermsContent>
          From Friday March 21st 12pm ET to Tuesday April 22nd 12pm ET (33 days), $1,000 will be donated per day (for a
          total of $33,000) to Community Forests International (CFI). Additionally, for every purchase of the Kiru
          Keychain Plushie with Reusable Bag made online at{' '}
          <StyledLink.Primary onClick={() => posthog.capture('makeGoodModal_makeshipLink', { modalLocation })} href="/">
            https://www.makeship.com
          </StyledLink.Primary>
          , Makeship will also donate 100% of the profits to CFI. Community Forests International works in Canada and
          Zanzibar to protect and restore forests, help communities adapt to climate change, build economic prosperity,
          and champion social equality. To learn more about CFI, visit{' '}
          <StyledLink.Primary
            onClick={() => posthog.capture('makeGoodModal_CFILink', { modalLocation })}
            href="https://www.forestsinternational.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            forestsinternational.org
          </StyledLink.Primary>
          . No portion of purchase is tax deductible. Makeship will receive no remuneration for conducting this
          campaign.
        </TermsContent>
      </TermsAndConditionsWrapper>
    </ModalContent>
  </Modal>
);

export default MakeGoodModal;
