import React from 'react';
import Image from 'next/image';
import posthog from 'posthog-js';
import styled from 'styled-components';
import StyledButton from '@makeship/core/lib/components/Button';
import { P1Styles, P1 } from '../../../Typography';
import Container from '../../../Container';
import config from '../../../../../config.json';
import MakeGoodModal from '../../../Modal/MakeGoodModal';

const { routes } = config;

const MastheadWrapper = styled.div`
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background: linear-gradient(180deg, #008a29 0%, #085317 100%);
`;

const MastheadContainer = styled(Container)`
  z-index: 2;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin: 0 16px;
  margin-bottom: 5px;
  justify-content: space-between;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    width: 80%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.maxContent}px) {
    width: 70%;
  }
`;

const DesktopPlushieImageWrapper = styled.div`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: block;
    width: 500px;
    height: 500px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    display: none;
  }
`;

const LargeDesktopPlushieImageWrapper = styled.div`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    display: block;
    width: 548px;
    height: 548px;
  }
`;

const SmallDesktopPlushieImageWrapper = styled.div`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    display: block;
    width: 424px;
    height: 424px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: none;
  }
`;

const MobilePlushieImageWrapper = styled.div`
  width: 282px;
  height: 282px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    display: none;
  }
`;

const InfoWrapper = styled.div`
  background: #f0ebe3;
  display: flex;
  padding: 24px 16px;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  max-width: 328px;
  max-height: 314px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    max-width: 400px;
    max-height: unset;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 480px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    max-width: 420px;
    padding: 42px 32px;
    justify-content: space-between;
    height: 410px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    max-width: 398px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    max-width: 468px;
  }
`;

const InfoLogoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 25px;
  max-width: 176px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    max-width: 288px;
  }
`;

const InfoIconWrapper = styled.div`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const LogoAndInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  justify-content: center;
`;

const InfoText = styled(P1)`
  color: ${({ theme }) => theme.colors.neutral7};
  text-align: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    ${P1Styles}
    color: ${({ theme }) => theme.colors.neutral7};
  }
`;

const StyledInfoText = styled.span`
  font-weight: 700;
`;

const InfoStyledButton = styled(StyledButton.Tertiary)`
  color: ${({ theme }) => theme.colors.neutral1};
  background: #004a0d;
  text-wrap: nowrap;
  border: transparent;
  padding: 14px 24px;
  width: 280px;
  &:active,
  &:hover {
    background: #003409;
    border: transparent;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: 336px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 280px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const InfoContentWrapper = styled.div``;

const ImageDesktopWrapper = styled.div`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: block;
  }
`;

const ImageMobileWrapper = styled.div`
  display: block;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: none;
  }
`;

const MakeGoodMasthead: React.FC = () => {
  const [isMakeGoodModalOpen, setIsMakeGoodModalOpen] = React.useState(false);
  return (
    <MastheadWrapper data-testid="make-good-masthead">
      <MastheadContainer>
        <InfoWrapper>
          <LogoAndInfoWrapper>
            <InfoLogoWrapper>
              <ImageDesktopWrapper>
                <Image
                  src="/assets/make-good/Make-Good_Logo-desktop-2.png"
                  width={689}
                  height={259}
                  quality={90}
                  alt="Make Good 2025 brought to you in support of CFI"
                  priority
                />
              </ImageDesktopWrapper>
              <ImageMobileWrapper>
                <Image
                  src="/assets/make-good/Make-Good_Logo.png"
                  width={689}
                  height={259}
                  quality={90}
                  alt="Make Good 2025 brought to you in support of CFI"
                  priority
                />
              </ImageMobileWrapper>
            </InfoLogoWrapper>
            <InfoIconWrapper
              onClick={() => {
                posthog.capture('homepage_MakeGood-masthead_info_click');
                setIsMakeGoodModalOpen(true);
              }}
            >
              <Image src="/assets/icons/info-icon-1.svg" width="16" height="16" quality={90} alt="Info icon" priority />
            </InfoIconWrapper>
          </LogoAndInfoWrapper>
          <InfoContentWrapper>
            <InfoText>
              To celebrate International Forest Day, we&apos;re <StyledInfoText>donating $1,000/day</StyledInfoText>!
              Additionally, <StyledInfoText>100% of profits</StyledInfoText> of the new Kiru Keychain will be donated to
              CFI.
            </InfoText>
          </InfoContentWrapper>
          <ButtonContainer>
            <a href={`${routes.shopMakeGood}`}>
              <InfoStyledButton onClick={() => posthog.capture('homepage_MakeGood-masthead_cta_click')}>
                Support The Make Good Event
              </InfoStyledButton>
            </a>
          </ButtonContainer>
        </InfoWrapper>
        <div>
          <a href={`${routes.shopMakeGood}`} onClick={() => posthog.capture('homepage_MakeGood-masthead_image_click')}>
            <LargeDesktopPlushieImageWrapper>
              <Image
                src="/assets/make-good/MakeGood_Masthead-ProductImage.png"
                width="548"
                height="548"
                alt="Make Good products"
                priority
              />
            </LargeDesktopPlushieImageWrapper>
            <DesktopPlushieImageWrapper>
              <Image
                src="/assets/make-good/MakeGood_Masthead-ProductImage.png"
                width="500"
                height="500"
                alt="Make Good products"
                priority
              />
            </DesktopPlushieImageWrapper>
            <SmallDesktopPlushieImageWrapper>
              <Image
                src="/assets/make-good/MakeGood_Masthead-ProductImage.png"
                width="424"
                height="424"
                alt="Make Good products"
                priority
              />
            </SmallDesktopPlushieImageWrapper>
            <MobilePlushieImageWrapper>
              <Image
                src="/assets/make-good/MakeGood_Masthead-ProductImage.png"
                width="282"
                height="282"
                alt="Make Good products"
                priority
              />
            </MobilePlushieImageWrapper>
          </a>
        </div>
      </MastheadContainer>
      <MakeGoodModal
        isOpen={isMakeGoodModalOpen}
        closeModal={() => {
          setIsMakeGoodModalOpen(false);
        }}
        modalLocation="home"
      />
    </MastheadWrapper>
  );
};
export default MakeGoodMasthead;
